<template>
  <div>
    <v-sheet rounded="lg" class="mb-2 mx-2 surface" v-for="(section,i) in items" :key="i">
      <flight-strip-section :title="section.title" :flight-strips="section.flightStrips" :loading="loading" class="surface"/>
    </v-sheet>
  </div>
</template>

<script>
import FlightStripSection from "@/components/shared/FlightStripSection";

export default {
  name: "FlightStripBay",
  components: {FlightStripSection,},
  props: {
    items: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
</style>