<template>
  <v-sheet class="surface">
    <app-btn
        class="ma-1"
        @click="createSortie">New Flight
    </app-btn>
    <flight-strip-bay :loading="loading" :items="flightStripBay" :last-updated="lastUpdated"/>
  </v-sheet>
</template>

<script>
import FlightStripBay from "@/components/shared/FlightStripBay";
import {mapActions, mapState} from "vuex";
import AppBtn from "@/components/app/Btn";
import {addMonths, subDays} from "date-fns";
import {getListOfAvailableSortieActions, getSortieStatus} from "@/util/sortie";

export default {
  name: "UpcomingSortiesPage",
  components: {
    AppBtn,
    FlightStripBay,
  },
  data() {
    return {
      polling: null,
      lastUpdated: null,
      activeGroup: null,
      searchStartDate: '',
      searchEndDate: ''
    }
  },
  computed: {
    ...mapState("sorties", ["sorties","sortieSearchQueryParams","availableActions"]),
    ...mapState("app", ["loading", "loggedInUser"]),
    ...mapState("user", ["flyingStatus","groupMembership"]),
    ...mapState("group", ["groups"]),
    searchGroupIds() {
      return this.loggedInUser ? this.groupMembership.map(g => g._id) : []
    },

    activeGroupDetails() {
      return this.groups.find(g => g._id == this.activeGroup)
    },

    sortieSearchQuery() {
      return {
        "groupIds": this.searchGroupIds,
        "startDate": this.searchStartDate,
        "endDate": this.searchEndDate
      }
    },

    userSortieStrips() {
      return this.sorties ? this.sorties.filter(s => s.owner == this.loggedInUser._id) : []
    },

    sortieWithStatusAndAvailableActions() {
      return this.userSortieStrips.map(s => ({ ...s, sortieStatus: getSortieStatus(s),availableActions:getListOfAvailableSortieActions(getSortieStatus(s),this.availableActions) }))
    },

    flightStripBay() {
      return [{title: 'My Upcoming Flights', flightStrips: this.sortieWithStatusAndAvailableActions }]
    },
  },
  methods: {
    ...mapActions("app", ["setLoading"]),
    ...mapActions("sorties", ["setActiveSortie", "setSorties", "searchSortiesByQuery"]),

    async createSortie() {
      await this.setLoading(true)
      await this.setActiveSortie(null);
      await this.setLoading(false)
      await this.$router.push('/sorties/entry')
    },
    async refreshSorties() {
      if (this.loggedInUser._id) {
        await this.setLoading(true)
        // await this.setSorties(this.activeGroupDetails)
        await this.searchSortiesByQuery(this.sortieSearchQuery)
        this.lastUpdated = new Date()
        await this.setLoading(false)
      }
    },
    async pollData() {
      await this.setLoading(true)
      // await this.setSorties(this.activeGroupDetails)
      await this.refreshSorties()
      await this.setLoading(false)
      this.polling = setInterval(async () => {
        if (this.loggedInUser._id) {
          await this.refreshSorties()
        }
      }, 10000);
    }
  },
  async mounted() {
    // Initial load of Vuex data
    const today = new Date()
    this.searchStartDate =  subDays(today, 365).toISOString().substring(0, 10),
    this.searchEndDate = addMonths(today, 12).toISOString().substring(0, 10),
    await this.pollData()
    // Setup list of items to fetch on a regular interval
  },
  beforeDestroy() {
    clearInterval(this.polling)
  },
}
</script>

<style>
</style>